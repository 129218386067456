import React from "react";

const Contact = () => {
  return (
    <div className="contact-wrapper">
      <div>rebeccastorm@gmail.com</div>
      <a href="https://www.instagram.com/rebeccaastorm/">@rebeccaastorm</a>
    </div>
  );
};

export default Contact;
